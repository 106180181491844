/*todo*/

html,
body {
  overflow-x: hidden;
}

.reroute-enter {
  opacity: 1;
  z-index: 1;
}
.reroute-enter.reroute-enter-active {
  -webkit-animation: slide-in-left 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-left 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.reroute-exit {
  opacity: 1;
  z-index: 0;
}
.reroute-exit.reroute-exit-active {
  -webkit-animation: slide-out-right 300ms cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: slide-out-right 300ms cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes slide-out-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
}
@keyframes slide-out-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
}
