button.btn {
  text-transform: unset;
}
button.btn-primary {
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  background-color: #0058ff;
  border: #0058ff;
}

button.btn-primary svg {
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

button.btn-primary:hover {
  color: #ffffff;
  background-color: #0047cc;
  border: #0047cc;
}

button.btn-primary:hover svg {
  color: #ffffff;
}

button.btn-primary:focus {
  color: #ffffff;
  background-color: #0047cc;
  border: #0047cc;
  box-shadow: 0 0 0 2px rgb(168 198 253);
}

button.btn-primary:focus svg {
  color: #ffffff;
}

button.btn-primary:active {
  color: #80acff;
  background-color: #0047cc;
  border: #0047cc;
}

button.btn-primary:active svg {
  color: #80acff;
}

button.btn-primary:disabled {
  color: #ffffff;
  background-color: #a8c6fd;
  border: #a8c6fd;
  box-shadow: unset;
}

button.btn-primary:disabled svg {
  color: #ffffff;
}

button.btn-secondary {
  font-size: 14px;
  font-weight: 600;
  color: #0058ff;
  background-color: #f2f7ff;
  border: #f2f7ff;
}

button.btn-secondary svg {
  font-size: 14px;
  font-weight: 600;
  color: #0058ff;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

button.btn-secondary:hover {
  color: #ffffff;
  background-color: #e5effe;
  border: #e5effe;
}

button.btn-secondary:hover svg {
  color: #ffffff;
}

button.btn-secondary:focus {
  color: #0058ff;
  background-color: #e5effe;
  border: #e5effe;
  box-shadow: 0 0 0 2px rgb(168 198 254);
}

button.btn-secondary:focus svg {
  color: #0058ff;
}

button.btn-secondary:active {
  color: #a8c6fd;
  background-color: #e5effe;
  border: #e5effe;
}

button.btn-secondary:active svg {
  color: #a8c6fd;
}

button.btn-secondary:disabled {
  color: #a8c6fd;
  background-color: #ffffff;
  box-shadow: unset;
  border: #f2f7ff;
}

button.btn-secondary:disabled svg {
  color: #a8c6fd;
}

button.btn-outline {
  font-size: 14px;
  font-weight: 600;
  color: #5a607f;
  background-color: #ffffff;
  border: 1px solid #d7dbec;
}

button.btn-outline svg {
  font-size: 14px;
  font-weight: 600;
  color: #5a607f;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

button.btn-outline:hover {
  color: #5a607f;
  background-color: #ffffff;
  border: 1px solid #7e84a3;
}

button.btn-outline:hover svg {
  color: #5a607f;
}

button.btn-outline:focus {
  background-color: #ffffff;
  border: 1px solid #7e84a3;
  box-shadow: 0 0 0 2px rgb(209 213 219);
}

button.btn-outline:focus svg {
  background-color: #ffffff;
}

button.btn-outline:active {
  color: #d7dbec;
  background-color: #ffffff;
  border: #7e84a3;
}

button.btn-outline:active svg {
  color: #d7dbec;
}

button.btn-outline:disabled {
  color: #d7dbec;
  background-color: #ffffff;
  border: 1px solid #d7dbec;
  box-shadow: unset;
}

button.btn-outline:disabled svg {
  color: #d7dbec;
}

/*//THIRD LEVEL OF BTN - OUTLINE GREEN */
button.btn-outline-red {
  font-size: 14px;
  font-weight: 600;
  color: #f0142f;
  background-color: #ffffff;
  border: 1px solid #f0142f;
}

button.btn-outline-red svg {
  font-size: 14px;
  font-weight: 600;
  color: #f0142f;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

button.btn-outline-red:hover {
  color: #bd0f23;
  background-color: #ffffff;
  border: 1px solid #bd0f23;
}

button.btn-outline-red:hover svg {
  color: #bd0f23;
}

button.btn-outline-red:focus {
  background-color: #ffffff;
  border: 1px solid #bd0f23;
  box-shadow: 0 0 0 2px rgb(240 146 156);
}

button.btn-outline-red:focus svg {
  color: #bd0f23;
  background-color: #ffffff;
}

button.btn-outline-red:active {
  color: #f0929c;
  background-color: #ffffff;
  border: 1px solid #f0142f;
}

button.btn-outline-red:active svg {
  color: #f0929c;
}

button.btn-outline-red:disabled {
  color: #f0929c;
  background-color: #ffffff;
  border: 1px solid #f0929c;
  box-shadow: unset;
}

button.btn-outline-red:disabled svg {
  color: #f0929c;
}

button.btn-danger {
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  border: #f0142f;
  background-color: #f0142f;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

button.btn-danger svg {
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

button.btn-danger:hover {
  color: #ffffff;
  background-color: #bd1526;
  cursor: pointer;
  border: #bd1526;
}

button.btn-danger:hover svg {
  color: #ffffff;
}

button.btn-danger:focus {
  color: #ffffff;
  background-color: #bd1526;
  box-shadow: 0 0 0 2px rgb(240 146 156);
  border: #bd1526;
}

button.btn-danger:focus svg {
  color: #ffffff;
}

button.btn-danger:active {
  color: #f0929c;
  background-color: #bd1526;
}

button.btn-danger:active svg {
  color: #f0929c;
}

button.btn-danger:disabled {
  color: #ffffff;
  background-color: #f0929c;
  box-shadow: unset;
}

button.btn-danger:disabled svg {
  color: #ffffff;
}

button.btn-success {
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  background-color: #21d59b;
}

button.btn-success svg {
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

button.btn-success:hover {
  color: #ffffff;
  background-color: #18a377;
  cursor: pointer;
}

button.btn-success:hover svg {
  color: #ffffff;
}

button.btn-success:focus {
  color: #ffffff;
  background-color: #18a377;
  box-shadow: 0 0 0 2px rgb(143 234 205);
}

button.btn-success:focus svg {
  color: #ffffff;
}

button.btn-success:active {
  color: #8feacd;
  background-color: #18a377;
}

button.btn-success:active svg {
  color: #8feacd;
}

button.btn-success:disabled {
  color: #ffffff;
  background-color: #8feacd;
  box-shadow: unset;
}

button.btn-success:disabled svg {
  color: #ffffff;
}

button.btn-header {
  height: 28px;
}
