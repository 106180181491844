input.input {
  background: #f3f3f4 0 0 no-repeat padding-box;
  border-radius: 4px;
  height: 40px;
  opacity: 1;
  padding: 0.75rem 0.5rem;
  border: 1px solid #f3f3f4;

  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

input.input:hover {
  border: 1px solid #f3f3f4;
  box-shadow: 0 0 0 2px rgb(168 198 253);
  background-color: #ffffff;
  cursor: text;
}

input.input:focus {
  border: 1px solid #0058ff;
  box-shadow: 0 0 0 2px rgb(168 198 253);
  background-color: #ffffff;
}

input.input:disabled {
  border: 1px solid #f3f3f4;
  color: #999999;
  background-color: #fafafa;
  box-shadow: unset;
  cursor: not-allowed;
}

input.input.input-search {
  padding-right: 30px;
}

select.select {
  background-color: #f3f3f4;
  border: 1px solid #f3f3f4;
  border-radius: 4px;

  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

select.select:hover {
  background-color: #ffffff;
  border: 1px solid #d7dbec;
  box-shadow: 0 0 0 2px rgb(168 198 253);
  cursor: pointer;
}

select.select:focus {
  background-color: #ffffff;
  border: 1px solid #0058ff;
  box-shadow: 0 0 0 2px rgb(168 198 253);
}

select.select:disabled {
  background-color: #fafafa;
  border: 1px solid #f3f3f4;
  color: #999999;
  cursor: not-allowed;
  box-shadow: unset;
}

textarea.textarea {
  background-color: #f3f3f4;
  border: 1px solid #f3f3f4;
  border-radius: 4px;

  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

textarea.textarea:hover {
  background-color: #ffffff;
  border: 1px solid #d7dbec;
  box-shadow: 0 0 0 2px rgb(168 198 253);
  cursor: text;
}

textarea.textarea:focus {
  background-color: #ffffff;
  border: 1px solid #0058ff;
  box-shadow: 0 0 0 2px rgb(168 198 253);
}

textarea.textarea:active {
  background-color: #ffffff;
  border: 1px solid #0058ff;
  box-shadow: 0 0 0 2px rgb(168 198 253);
}

textarea.textarea:disabled {
  background-color: #fafafa;
  border: 1px solid #f3f3f4;
  color: #999999;
  cursor: not-allowed;
  box-shadow: unset;
}
