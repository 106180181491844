@import 'btn.css';
@import 'form.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /*ROOT VALUE 1rem*/
  html {
    font-size: 14px;
  }

  label {
    color: #000000;
    font-size: 12px;
    font-weight: 600;
  }

  .card {
    padding: 30px 30px;
    background: #ffffff 0 0 no-repeat padding-box;
    box-shadow: 0 1px 4px #15223214;
    border-radius: 6px;
    opacity: 1;
  }

  .card-login {
    width: 457px;
    padding: 50px 30px;
    background: #ffffff 0 0 no-repeat padding-box;
    box-shadow: 0 1px 4px #15223214;
    border-radius: 6px;
    opacity: 1;
  }

  .box {
    border: 1px solid #e6e9f4;
    border-radius: 6px;
    padding: 20px;
  }

  .box-files {
    border: 2px solid #e6e9f4;
    border-radius: 6px;
    padding: 20px;
  }

  .box-files:hover {
    border: 2px dashed #0058ff;
    border-radius: 6px;
    padding: 20px;
    background-color: #f4f7fc;
  }

  .p-main {
    padding: 20px;
  }

  .p-sub-main {
    padding: 10px 20px;
  }

  .pl-20 {
    padding-left: 20px;
  }

  .pr-20 {
    padding-left: 20px;
  }

  .flex-05 {
    flex: 0.5;
  }

  .image-wrapper {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #d7dbec;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    cursor: pointer;
    transition: box-shadow 0.15s ease-in-out;
  }
  .image-wrapper:hover {
    box-shadow: 0 0 0 2px rgb(168 198 253);
    cursor: pointer;
  }

  /*.image {*/
  /*  width: 55px;*/
  /*  height: auto;*/
  /*}*/

  .image {
    width: 30px;
    height: auto;
    max-height: 100%;
    max-width: inherit;
  }

  .square {
    width: 2rem;
    height: 2rem;
    border-radius: 4px;
    background-color: #5a607f;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .square-amount {
    color: #ffffff;
    font-weight: 600;
  }

  .label-blue {
    color: #0058ff;
    background-color: #e5effe;
    font-size: 12px;
    font-weight: 600;
    width: 42px;
    height: 20px;
    padding: 4px 8px;
    border-radius: 4px;
  }

  .label-green {
    color: #21d59b;
    background-color: #e8fbf5;
    font-size: 12px;
    font-weight: 600;
    width: 42px;
    height: 20px;
    padding: 4px 8px;
    border-radius: 4px;
  }

  .label-red {
    color: #f0142f;
    background-color: #fee7e9;
    font-size: 12px;
    font-weight: 600;
    width: 42px;
    height: 20px;
    padding: 4px 8px;
    border-radius: 4px;
  }

  .label-yellow {
    color: #f99600;
    background-color: #fff4e5;
    font-size: 12px;
    font-weight: 600;
    width: 42px;
    height: 20px;
    padding: 4px 8px;
    border-radius: 4px;
  }

  .label-purple {
    color: #8950fc;
    background-color: #f3edff;
    font-size: 12px;
    font-weight: 600;
    width: 42px;
    height: 20px;
    padding: 4px 8px;
    border-radius: 4px;
  }
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  height: 3rem;
  width: 3rem;
  display: inline-block;
  vertical-align: text-bottom;
  border: 0.25em solid #0058ff;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner 0.5s linear infinite;
  -webkit-animation: spinner 0.5s linear infinite;
}

div.card-body {
  padding: unset;
}

ul a.menuItem-active {
  border-left: 4px solid #0058ff;
}

ul a.menuItem-active li.flex.justify-center span {
  color: #0058ff;
}

.scrollable-content {
  overflow: auto;
  scroll-behavior: smooth;
  min-height: 442px;
}

.notification-container {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 10;
  padding: 5px 10px;
  background-color: #fff;
  width: 400px;
  height: 0;
  border-radius: 7px;
  border: none;
  font-size: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: 0;
  transition: all 500ms;
}
.notification-container.active {
  opacity: 1;
  height: 80px;
  border: 4px solid #57b8ff;
}
.notification-container.active.warning {
  border: 4px solid #bd1526;
  background-color: #bd1526;
  color: #fff;
  width: 94.25%;
}

/* Webkit scrollbar styling */

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f175;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.table-stripped > div:nth-child(odd) {
  background-color: rgba(198, 198, 198, 0.25);
}

.grid-cols-13 {
  grid-template-columns: repeat(13, minmax(0, 1fr));
}

/*.inline*/

.react-datepicker__input-container input {
  max-width: 80px;
}
